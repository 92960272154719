import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Artikeln = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />
    <Notification />

    <h1>Artikeln</h1>

    <a href="https://totaltdcs.com/latest-news-tdcs/">Letzte Neuigkeiten</a>
  </Layout>
)

export default Artikeln
